import React from 'react';
import { graphql } from 'gatsby';

import BaseLayout from '../../components/templates/BaseLayout';
import PrimaryHeader from '../../components/atoms/typography/PrimaryHeader';
import SubHeader from '../../components/atoms/typography/SubHeader';
import Headline from '../../components/atoms/typography/Headline';
import Spacer from '../../components/atoms/utility/Spacer';
import SEO from '../../components/atoms/utility/seo';
import JumboPanel from '../../components/atoms/JumboPanel';
import DownloadAppCTA from '../../components/atoms/callouts/DownloadAppCTA';

//Section Content//
const marketplaceFeatures = {
  ['Compete.']: [
    {
      name: 'How Do Bobcats Party?',
      description:
        'The flockx mobile app is a must-have for students looking to make the most of their time in San Marcos and Austin. The app provides a comprehensive map of all the best things to do and see around the area.'
    },
    {
      name: 'Never Miss Out',
      description:
        'From live music to breweries, tacos, barbecue, bars, nature, and more, there’s a community for everyone. It’s full of real-time recommendations from community members, so users can stay in the know.'
    }
  ],
  ['Discover.']: [
    {
      name: 'East 6th St. Austin',
      description:
        'East 6th Street is West 6th’s laid-back twin. Enjoy breweries and shops in walking distance and some of the best tacos and international cuisine Austin has to offer.'
    },
    {
      name: 'South Congress, Austin',
      description:
        'Less than half an hour from campus, South Congress is the closest place to get the true Weird Austin experience. From boutiques to bars, ice cream to art, this district has it all.'
    },
    {
      name: 'Float The River',
      description:
        'Floating the river is a Texas tradition especially if you live in central Texas. Download the app to see where the most tube-able rivers are.'
    }
  ],
  //Optional 3rd Section//
  ['Level Up.']: [
    {
      name: 'Community Reputation',
      description:
        'Share cool spots and the memories you’ve had with your communities to increase your clout.'
    },
    {
      name: 'Community Rating',
      description:
        'Rate your memories using ratings that matter to your community to help community members grow.'
    },
    {
      name: 'Community Support',
      description:
        'Help new community members learn about your community and where to get started.'
    }
  ]
};

export default function TexasStatePage({
                                         data: {
                                           TexasStateHeroImg,
                                           TexasStateMobileHeroImg,
                                           buildImg,
                                           growImg,
                                           openGraphImg
                                         }
                                       }) {
  //Section 1 Image & Header//
  const jumboPanelOne = {
    image: {
      imageSrc: buildImg,
      imageAlt: 'Dirty 6th Street at night time'
    },
    headline: {},
    paragraph: {},
    list: {
      name: 'Find Your Community',
      listContent: marketplaceFeatures['Compete.'],
      headerColor: '#7593FF'
    }
  };

  //Section 2 Image & Header//
  const jumboPanelTwo = {
    image: {
      imageSrc: growImg,
      imageAlt: 'paddle boarding on lady bird lake in Austin'
    },
    headline: {},
    paragraph: {},
    list: {
      name: 'A Stone Throw Away',
      listContent: marketplaceFeatures['Discover.'],
      headerColor: '#63D2E1'
    }
  };

  //Hero Content & Meta Data//
  return (
    <BaseLayout
      heroContent={TexasStatePageHeroContent}
      heroImg={TexasStateHeroImg}
      mobileHeroImg={TexasStateMobileHeroImg}
      heroAlt={'Downtown San Marcos.'}
    >
      <SEO
        title='Texas State Universtiy | Find Your Community'
        keywords={[
          'Texas State University',
          'Texas State',
          'find things to do',
          'communities',
          'San Marcos',
          'Austin',
          'mobile app',
          'flockx',
          'hidden gems',
          'barbecue',
          'tacos',
          'breweries'
        ]}
        customSeoImage={openGraphImg}
        description={`Make the most of your time at Texas State Universtiy. Download flockx to find things to do around San Marcos and the Greater Austin Metro today.`}
      />
      <Spacer />
      <Spacer className='block lg:hidden' />
      <JumboPanel content={jumboPanelOne} />
      <Spacer />
      <Spacer className='block md:hidden' />
      <JumboPanel content={jumboPanelTwo} imageSide='left' />
      <Spacer />
      <Spacer className='block lg:hidden' />
      <div className='mx-auto text-left sm:text-center max-w-3xl mb-4 lg:mb-12'>
        <Headline
          spanText='Ready for a new way to explore?'
          spanColor='#000'
          className='mx-auto text-center max-w-[350px] md:max-w-xl mb-4 lg:mb-12 capitalize'
        />
        <div className='relative z-10 text-center'>
          <DownloadAppCTA containerStyles='justify-center' />
        </div>
      </div>
      <Spacer />
      <Spacer className='block lg:hidden' />
    </BaseLayout>
  );
}

//Hero Content (H1 tag)//
function TexasStatePageHeroContent() {
  return (
    <div className='-mt-[15%] sm:mt-0'>
      <PrimaryHeader
        headerText='An App To Find Your Community At Texas State'
        className=' mb-2 sm:mb-4 md:mb-8 lg:mb-8 text-white capitalize font-medium max-w-[390px] sm:max-w-none md:max-w-4xl lg:max-w-5xl lg:mr-auto lg:ml-0'
      />
      <SubHeader
        headerText='Discover things to do in San Marcos, Austin and the areas surrounding Texas State University.'
        className='mb-4 md:mb-8 lg:mb-10 xl:mb-12 text-white font-light max-w-xs sm:max-w-sm lg:max-w-4xl sm:mx-0'
      />
      <DownloadAppCTA containerStyles='justify-start' />
      <p className='block sm:pt-0 font-light sm:text-lg md:text-xl lg:text-2xl text-white'>
        Available in the US
      </p>
    </div>
  );
}

//GraphQL//
export const query = graphql`
    query TexasStatePageQuery {
        TexasStateHeroImg: file(name: { eq: "texas-stateHero" }) {
            childImageSharp {
                gatsbyImageData
            }
        }
        TexasStateMobileHeroImg: file(name: { eq: "texas-stateMobile" }) {
            childImageSharp {
                gatsbyImageData
            }
        }
        buildImg: file(name: { eq: "texas-stateBuild" }) {
            childImageSharp {
                gatsbyImageData(width: 889)
            }
        }
        growImg: file(name: { eq: "texas-stateGrow" }) {
            childImageSharp {
                gatsbyImageData(width: 889)
            }
        }
        openGraphImg: file(name: { eq: "texas-stateThumb" }) {
            childImageSharp {
                gatsbyImageData
            }
        }
    }
`;
